<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="direccion.calle"
            label="Calle"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="direccion.numero_exterior"
            label="Número Exterior"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="direccion.numero_interior"
            label="Número Interior"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="direccion.codigo_postal"
            label="Código Postal"
            type="number"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="direccion.colonia"
            label="Colonia"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="direccion.ciudad"
            label="Ciudad"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="direccion.estado"
            label="Estado"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="direccion.numero_contacto"
            label="Número de contacto"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { validationMixin } from '@/mixins/formsValidations.js'

export default {
  mixins: [validationMixin],
  props: {
    direccion: {
      type: Object,
    },
  }
}
</script>

<style>

</style>